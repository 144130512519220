import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FactoryProvider} from "@angular/core";
import {environment} from "@env/environment";
import {BrandCoverageByLanguage} from "@core/providers/rawCoverage/BrandCoverageByLanguage";
import {LanguageCoverageByWave} from "@core/providers/rawCoverage/LanguageCoverageByWave";

export class RawCoverageProviderApi {

  constructor(private http: HttpClient) {
  }

  public brandCoverageByLanguage(): Observable<BrandCoverageByLanguage[]> {
    return this.http.get<BrandCoverageByLanguage[]>(`${environment.apiUrl}/raws/brandCoverageByLanguage`);
  }

  public languageCoverageByWave(): Observable<LanguageCoverageByWave[]> {
    return this.http.get<LanguageCoverageByWave[]>(`${environment.apiUrl}/raws/languageCoverageByWave`);
  }

}

export const rawCoverageProvider: FactoryProvider = {
  provide: RawCoverageProviderApi,
  useFactory: (http: HttpClient) => new RawCoverageProviderApi(http),
  deps: [HttpClient]
};
