import {IValidatorResult} from '@core/form-validators/IValidatorResult';
import {FormGroup} from "@angular/forms";

export const passwordsMatchValidator = (group: FormGroup): IValidatorResult | null => {
  const pass = group.controls.password;
  const confirm = group.controls.confirmPassword;
  const invalid = pass.dirty && confirm.dirty && pass.value !== confirm.value;
  if (invalid) {
    return {
      compare: {
        message: 'pages.users.errors.passwordNotMatch'
      }
    };
  } else {
    return null;
  }
};

