import {FormGroup} from "@angular/forms";
import {FormControls} from "@core/interfaces/FormControls";
import {FormValue} from "@core/interfaces/FormValue";
import {Observable} from "rxjs";

export class FormGroupBase<C extends FormControls> extends FormGroup {

  public readonly value!: FormValue<C>;
  public readonly valueChanges!: Observable<FormValue<C>>;
  public controls!: C;

  public setValue(value: FormValue<C>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }

  public patchValue(value: Partial<FormValue<C>>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }
}
