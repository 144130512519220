import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FactoryProvider} from "@angular/core";
import {environment} from "@env/environment";
import {IRawPayload} from "@core/providers/raw/IRawPayload";
import {IRawRatings} from "./IRawRatings";
import {IRawQuestionRatings} from "@core/providers/raw/IRawQuestionRatings";
import {IRawOtherAnswer} from "@core/providers/raw/IRawOtherAnswer";
import {IRawOtherAnswerPayload} from "@core/providers/raw/IRawOtherAnswerPayload";
import {IList} from './IList';
import {IWave} from "@core/providers/wave/IWave";
import {RawOtherAnswerType} from "@core/providers/raw/RawOtherAnswerType";

export class ChartDataApi implements IList {

  constructor(private http: HttpClient) {
  }

  public otherAnswerWaves(type: RawOtherAnswerType): Observable<IWave[]> {
    return this.http.get<IWave[]>(`${environment.apiUrl}/raws/other-answers/${type}/waves`);
  }

  public otherAnswers(type: RawOtherAnswerType, payload: IRawOtherAnswerPayload): Observable<IRawOtherAnswer[]> {
    return this.http.post<IRawOtherAnswer[]>(`${environment.apiUrl}/raws/other-answers/${type}`, payload);
  }

  public ratings(payload: IRawPayload): Observable<IRawRatings[]> {
    return this.http.post<IRawRatings[]>(`${environment.apiUrl}/raws/ratings`, payload);
  }

  public questionRatings(payload: IRawPayload): Observable<IRawQuestionRatings[]> {
    return this.http.post<IRawQuestionRatings[]>(`${environment.apiUrl}/raws/question-ratings`, payload);
  }

  public healthCareServicesUsage(payload: IRawPayload): Observable<IRawQuestionRatings[]> {
    return this.http.post<IRawQuestionRatings[]>(`${environment.apiUrl}/raws/services-usage`, payload);
  }

  public satisfactionDegree(payload: IRawPayload): Observable<IRawRatings[]> {
    return this.http.post<IRawRatings[]>(`${environment.apiUrl}/raws/degree-of-satisfaction`, payload);
  }

  public list<T>(endpoint: string, payload: IRawPayload): Observable<T[]>{
    return this.http.post<T[]>(`${environment.apiUrl}${endpoint}`, payload);
  }

}

export const rawDataProvider: FactoryProvider = {
  provide: ChartDataApi,
  useFactory: (http: HttpClient) => new ChartDataApi(http),
  deps: [HttpClient]
};
