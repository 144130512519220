import {FormControl} from '@angular/forms';
import {IValidatorResult} from '@core/form-validators/IValidatorResult';
import {isEmpty} from '@core/form/isEmpty';

const rules = [
  /[0-9]+/,
  /[a-z]+/,
  /[A-Z]+/,
  /[+,\-/:;<=>?@\\\[\]^_'{|}~!"#$%&()*]+/,
  /[a-zA-Z0-9+,\-/:;<=>?@\\\[\]^_'{|}~!"#$%&()*]{8,80}$/
];

export const alphanumericValidator = (control: FormControl): IValidatorResult | null => {
  if (isEmpty(control.value)) {
    return null;
  }
  return rules.every(r => control.value.match(r)) ? null : {
    alphanumeric: {
      message: 'errors.alphanumeric'
    }
  };
};
