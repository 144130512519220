import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTableComponent} from './data-table.component';
import {CellContentComponent} from './cell-content/cell-content.component';
import {CellHeaderTmplDirective} from './cell-content/cell-header-tmpl.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DataTableComponent,
    CellContentComponent,
    CellHeaderTmplDirective
  ],
  exports: [
    DataTableComponent,
    CellContentComponent,
    CellHeaderTmplDirective
  ]
})
export class DataTableModule {
}
