import {Component, ContentChild, Input} from '@angular/core';
import {CellHeaderTmplDirective} from './cell-header-tmpl.directive';

@Component({
  selector: 'cell-content',
  templateUrl: './cell-content.component.html',
  styleUrls: ['./cell-content.component.scss']
})
export class CellContentComponent {

  @Input()
  public header!: string;

  @ContentChild(CellHeaderTmplDirective, {static: false})
  public headerTmpl!: CellHeaderTmplDirective;

}
