<ng-container *ngIf="headerTmpl?.template;then custom else def"></ng-container>

<ng-template #custom>
  <div class="header">
    <ng-container *ngTemplateOutlet="headerTmpl.template"></ng-container>
  </div>
</ng-template>

<ng-template #def>
  <div class="header">
    {{header}}
  </div>
</ng-template>

<div class="content">
  <ng-content></ng-content>
</div>
