import {AbstractControl} from '@angular/forms';

const patterns = {
  default: /^[\d+]{11,12}$/
};

export const phoneValidator = (message: string = 'errors.phone') => (control: AbstractControl) => {
  if (!control.value) {
    return null;
  }
  const pattern = patterns.default;
  if (control.value.match(pattern)) {
    return null;
  }
  return {
    phone: {
      message
    }
  };
}

