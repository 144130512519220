export enum RawRatingsType {
  MEAN_RATINGS = 'MEAN_RATINGS',
  TOP_2_BOX = 'TOP_2_BOX',
  TOP_3_BOX = 'TOP_3_BOX',
  BOTTOM_2_BOX = 'BOTTOM_2_BOX',
  NET_PROMOTER_SCORE = 'NET_PROMOTER_SCORE',
  TELEPHONE_APPOINTMENT = 'TELEPHONE_APPOINTMENT',
  VIDEO_APPOINTMENT = 'VIDEO_APPOINTMENT',
  IN_PERSON_APPOINTMENT = 'IN_PERSON_APPOINTMENT',
  CONSIDER_PHONE_APPOINTMENT = 'CONSIDER_PHONE_APPOINTMENT',
  CONSIDER_VIDEO_APPOINTMENT = 'CONSIDER_VIDEO_APPOINTMENT',
  CONSIDER_IN_PERSON_APPOINTMENT = 'CONSIDER_IN_PERSON_APPOINTMENT',
  SOMEWHAT_OR_VERY_SATISF_TEL_APP = 'SOMEWHAT_OR_VERY_SATISF_TEL_APP',
  SOMEWHAT_OR_VERY_SATISF_VID_APP = 'SOMEWHAT_OR_VERY_SATISF_VID_APP',
  SOMEWHAT_OR_VERY_SATISF_IN_PERS_APP = 'SOMEWHAT_OR_VERY_SATISF_IN_PERS_APP',
  PLAN_TO_GET_VACCINATED_FOR_COVID_19 = 'PLAN_TO_GET_VACCINATED_FOR_COVID_19',
  EQUITY_AND_WELLNESS_RESPECT = 'EQUITY_AND_WELLNESS_RESPECT',
  EQUITY_AND_WELLNESS_OVERALL = 'EQUITY_AND_WELLNESS_OVERALL',
  EQUITY_AND_WELLNESS_HEALTH_GOALS = 'EQUITY_AND_WELLNESS_HEALTH_GOALS',
  EQUITY_AND_WELLNESS_CONTROL_OF_HEALTH = 'EQUITY_AND_WELLNESS_CONTROL_OF_HEALTH',
  EQUITY_AND_WELLNESS_INTERPRETERS_DELAY = 'EQUITY_AND_WELLNESS_INTERPRETERS_DELAY',
  EQUITY_AND_WELLNESS_INTERPRETERS_WAIT_TIME = 'EQUITY_AND_WELLNESS_INTERPRETERS_WAIT_TIME',
}
